<template>
  <v-container fluid>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="name">Name</label>
      </v-col>
      <v-col md="5">
        <v-text-field
          color="cyan"
          v-model.trim="usersDetail.first_name"
          dense
          filled
          flat
          solo
          label="First Name"
          :rules="[validateRules.required(usersDetail.last_name, 'First Name')]"
        ></v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field
          color="cyan"
          v-model.trim="usersDetail.last_name"
          dense
          filled
          flat
          solo
          label="Last Name"
          :rules="[validateRules.required(usersDetail.last_name, 'Last Name')]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="name">Contact</label>
      </v-col>
      <v-col md="10">
        <v-text-field
          color="cyan"
          v-model.trim="usersDetail.phone_number"
          dense
          filled
          flat
          solo
          label="Contact"
          :rules="[validateRules.required(usersDetail.phone_number, 'Contact')]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="role">Role</label>
      </v-col>
      <v-col md="10">
        <v-select
          dense
          filled
          item-text="name"
          item-value="id"
          item-color="cyan"
          solo
          flat
          color="cyan"
          :items="items"
          label="Role"
          :rules="[validateRules.required(usersDetail.role, 'Role')]"
          v-model.trim="usersDetail.role"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="password">Password</label>
      </v-col>
      <v-col md="10">
        <v-text-field
          v-model.trim="usersDetail.password"
          type="password"
          color="cyan"
          dense
          filled
          flat
          solo
          label="Password"
          :rules="[
            validateRules.confirmPassword(
              usersDetail.confirm_password,
              'Confirm Password',
              usersDetail.password
            )
          ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="confirm_password">Confirm Password</label>
      </v-col>
      <v-col md="10">
        <v-text-field
          v-model.trim="usersDetail.confirm_password"
          type="password"
          color="cyan"
          dense
          filled
          flat
          solo
          label="Confirm Password"
          :rules="[
            validateRules.confirmPassword(
              usersDetail.confirm_password,
              'Confirm Password',
              usersDetail.password
            )
          ]"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET } from "@/core/services/store/request.module";
export default {
  name: "Users-Detail",
  mixins: [ValidationMixin],
  watch: {
    usersDet: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("updateUsersDetail", param);
      }
    }
  },
  data() {
    return {
      items: [],
      usersDet: {
        first_name: null,
        last_name: null,
        phone_number: null,
        password: null,
        confirm_password: null
      }
    };
  },
  props: {
    usersDetail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      }
    }
  },
  methods: {
    getRoles() {
      this.$store
        .dispatch(GET, {
          url: "/roles"
        })
        .then(({ data }) => {
          this.items = data;
        })
        .catch(error => {
          this.logError(error);
        });
    }
  },
  mounted() {
    this.usersDet = this.usersDetail;
    this.getRoles();
  }
};
</script>
