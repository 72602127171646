<template>
  <ListingTemplate>
    <template v-slot:body>
      <v-container fluid class="pt-0">
        <v-tabs v-model="userTab" align-with-title>
          <v-tabs-slider color="cyan"></v-tabs-slider>
          <v-tab
            v-for="(item, index) in usersTabs"
            :key="index"
            :href="'#tab-' + item.key"
          >
            {{ item.title }}
          </v-tab>
          <v-tabs-items v-model="userTab">
            <template v-for="(tab, index) in usersTabs">
              <v-tab-item :value="'tab-' + tab.key" :key="index">
                <component :is="tab.template" />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </template>
  </ListingTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import UserTemplate from "@/view/pages/profile/UserTableTemplate";
import UserRoles from "@/view/pages/profile/UserRoleTableTemplate";
export default {
  name: "Users",
  components: {
    ListingTemplate
  },
  data() {
    return {
      userTab: null,
      usersTabs: [
        {
          disabled: false,
          key: "users",
          template: UserTemplate,
          title: "Users"
        },
        {
          disabled: false,
          key: "usersRoles",
          template: UserRoles,
          title: "Roles"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
  }
};
</script>
